import React, { useEffect, useState } from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import { FiCheckCircle } from 'react-icons/fi';
import i18next from '../../languages/i18n.config';
import { AdBoardButton } from '../AdBoardButton';

export const AdBoardCompletedTaskButton = ({
  buttonContent,
  onClick,
  endIcon,
}: {
  buttonContent: React.ReactNode;
  onClick: () => void;
  endIcon?: React.ReactNode;
}) => {
  const { palette } = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Wait for the component to mount and trigger the animation after a small delay
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Small delay to ensure the button doesn't show briefly before animating
    return () => clearTimeout(timer);
  }, []);
  return (
    <Box
      sx={{
        mt: 2,
        p: 3,
        borderRadius: 2,
        gap: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)`,
        backgroundColor: palette.keyops.white.main,
        position: 'sticky',
        bottom: 0,
        zIndex: 10,
        transform: isVisible ? 'translateY(0)' : 'translateY(100%)',
        opacity: isVisible ? 1 : 0,
        visibility: isVisible ? 'visible' : 'hidden',
        transition: 'transform 0.4s ease-in-out, opacity 0.4s ease-in-out',
      }}
    >
      <Box display={'flex'} flexDirection='row' alignItems='center' gap={1}>
        <FiCheckCircle
          data-testid='fi-check-circle'
          color={palette.keyops.teal}
          fontSize='18px'
        />

        <Typography
          fontWeight={400}
          fontSize='16px'
          lineHeight='24px'
          color={palette.keyops.blue.dark}
        >
          {i18next.t('ad_board.completed_task_button.task_completed')}
        </Typography>
      </Box>
      <AdBoardButton onClick={onClick} endIcon={endIcon ?? ''}>
        {buttonContent}
      </AdBoardButton>
    </Box>
  );
};
