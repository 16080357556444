import { useState } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AllAdBoardDiscussionsDto } from '@keyops-hcp/dtos';

import { getAdBoardCurrentDiscussions } from '../api/adBoardDiscussions';
import { queryClient } from '../wrappers';

/**
 *
 * @param {string} adBoardId - The id of the current Ad Board
 * @param {string} currentSectionId - The id of the current section
 * @returns
 */
export const useDiscussionsByAdBoardId = (
  adBoardId: string
): UseQueryResult<AllAdBoardDiscussionsDto, Error> => {
  const [etag, setEtag] = useState<string | undefined>(undefined); // Manage the eTag

  return useQuery({
    queryKey: ['getAdBoardCurrentDiscussions', adBoardId],
    // Pass the current ETag to the query function
    queryFn: async () => {
      const { allDiscussions, eTag: newEtag } =
        await getAdBoardCurrentDiscussions(
          adBoardId,
          etag // Use the stored ETag
        );

      // Update the ETag if a new one is returned from the server
      if (newEtag) {
        setEtag(newEtag);
      }

      // If allDiscussions is undefined (304 Not Modified), use the cached data
      if (!allDiscussions) {
        return queryClient.getQueryData([
          'getAdBoardCurrentDiscussions',
          adBoardId,
        ]);
      }

      return allDiscussions;
    },
    enabled: !!adBoardId,
  });
};
