import { AllAdBoardDiscussionsDto, DiscussionDto } from '@keyops-hcp/dtos';
import { t } from 'i18next';

import { axiosCall } from '../utils/api';
import { AD_BOARDS_ENDPOINT } from '../utils/api-routes';

export const getAdBoardGeneralDiscussion = async (
  adBoardId: string
): Promise<DiscussionDto> => {
  try {
    const response = await axiosCall(
      false,
      'get',
      AD_BOARDS_ENDPOINT + `/${adBoardId}/general-discussion`
    );

    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(
      response?.data?.message ||
        t('apiErrorMessages.no_ad_board_general_discussion_found')
    );
  } catch (error) {
    console.error(
      'Error fetching data getAdBoardDiscussionsByAdBoardId:',
      error
    );
    throw new Error(
      error?.response?.data?.message ||
        t('apiErrorMessages.no_ad_board_general_discussion_found')
    );
  }
};

/**
 * getAdBoardCurrentDiscussions gets all discussions related to the given AdBoard and Section
 * @param {string} adBoardId - The id of the current Ad Board
 * @param {string} currentSectionId - The id of the current section
 * @returns both the Ad Board general discussion and the current section related discussion, if any
 */
export const getAdBoardCurrentDiscussions = async (
  adBoardId: string,
  eTag: string
): Promise<{ allDiscussions: AllAdBoardDiscussionsDto; eTag?: string }> => {
  try {
    const endpoint =
      AD_BOARDS_ENDPOINT + `/${adBoardId}/accessible-discussions`;
    const response = await axiosCall(
      false,
      'get',
      endpoint,
      undefined,
      undefined,
      undefined,
      {
        ...(eTag && { 'If-None-Match': eTag }), // Include ETag if available
      }
    );

    if (response.status === 304) {
      // No changes in the data, return an empty result and let React Query use cached data
      return { allDiscussions: undefined };
    }

    if (response.status !== 304) {
      const newEtag = response.headers['ETag']; // Axios stores headers in lowercase

      // Return the eTag if defined
      return {
        allDiscussions: response.data.data,
        ...(newEtag && { eTag: newEtag }),
      };
    }

    if (response.status === 200) {
      return { allDiscussions: response.data.data };
    }
    throw new Error(
      response?.data?.message ||
        t('apiErrorMessages.no_ad_board_general_discussion_found')
    );
  } catch (error) {
    console.error(
      'Error fetching data getAdBoardDiscussionsByAdBoardId:',
      error
    );
    throw new Error(
      error?.response?.data?.message ||
        t('apiErrorMessages.no_ad_board_general_discussion_found')
    );
  }
};
