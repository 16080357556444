import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import {
  AdBoardAttachmentSectionProgressDto,
  AdBoardDiscussionSectionProgressDto,
  AdBoardParticipantsDto,
  AdBoardSectionProgressDto,
  AdBoardSurveySectionProgressDto,
  AdBoardVideoSectionProgressDto,
  SectionDto,
} from '@keyops-hcp/dtos';

import { updateSectionProgress } from '../api/sections';
import { getOneAdBoard, getParticipantDetails } from '../api/adBoards';
import { getAllAdBoardParticipants } from '../api/participants';
import { AdBoardContext } from '../context';

export const useHcpAdBoardContext = () => {
  const { adBoardId, sectionId } = useParams();
  const queryClient = useQueryClient();

  // Use useQueries to fetch adBoard, sections, and participant data simultaneously
  const [adBoardQuery, participantQuery, allParticipantsQuery] = useQueries({
    queries: [
      {
        queryKey: ['adBoard', adBoardId],
        queryFn: () => getOneAdBoard(adBoardId),
      },
      {
        queryKey: ['participant', adBoardId],
        queryFn: () => getParticipantDetails(adBoardId),
        onSuccess: (data: AdBoardParticipantsDto) => {
          const progress = data?.progress?.progressDetails?.sectionProgress;
          if (
            data?.role !== 'moderator' &&
            (!progress || progress.length <= 0)
          ) {
            updateProgress(undefined);
          }
        },
      },
      {
        queryKey: ['all participants', adBoardId],
        queryFn: () => getAllAdBoardParticipants(adBoardId),
      },
    ],
  });

  const isLoading =
    adBoardQuery.isLoading ||
    participantQuery.isLoading ||
    allParticipantsQuery.isLoading;
  const isError =
    adBoardQuery.isError ||
    participantQuery.isError ||
    allParticipantsQuery.isError;

  const adBoardData = adBoardQuery?.data;
  const sectionsData = adBoardData?.sections;
  const sectionGroupsData = adBoardData?.sectionGroups;
  const participantData = participantQuery?.data;
  const participantId = participantData?.id;
  const allParticipantsData = allParticipantsQuery?.data;

  const isModerator = participantData?.role === 'moderator';

  // Memoize progress-related calculations
  const progress = useMemo(
    () => participantData?.progress?.progressDetails?.sectionProgress,
    [participantData]
  );

  const finishedSections = useMemo<AdBoardSectionProgressDto[]>(
    () =>
      progress?.filter(
        (progressEntry: AdBoardSectionProgressDto) => progressEntry.finished
      ) || [],
    [progress]
  );

  const accessibleSections = useMemo<AdBoardSectionProgressDto[]>(
    () =>
      progress?.filter(
        (progressEntry: AdBoardSectionProgressDto) => progressEntry.accessible
      ) || [],
    [progress]
  );

  // TODO: Use latestSection to determine currentSectionDetails & currentSectionProgress (participantData?.progress?.latestSection)
  const currentSectionDetails = useMemo(
    () => sectionsData?.find((section: SectionDto) => section.id === sectionId),
    [sectionsData, sectionId]
  );

  const currentSectionProgress = useMemo(
    () =>
      progress?.find(
        (progressEntry: AdBoardSectionProgressDto) =>
          progressEntry.sectionId === sectionId
      ),
    [sectionId, progress, participantData?.progress?.latestSection]
  );

  // Memoize the refetchAllData function
  const refetchAllData = useCallback(() => {
    queryClient.invalidateQueries(['adBoard', adBoardId]);
    queryClient.invalidateQueries(['participant', adBoardId]);
  }, [queryClient, adBoardId]);

  const { mutate: updateProgress } = useMutation(
    async (
      data:
        | AdBoardVideoSectionProgressDto
        | AdBoardAttachmentSectionProgressDto
        | AdBoardSurveySectionProgressDto
        | AdBoardDiscussionSectionProgressDto
    ) => {
      if (!participantId || !sectionId || isModerator) return;
      return await updateSectionProgress(sectionId, participantId, data);
    },
    {
      // On success, invalidate the relevant queries to refetch data
      onSuccess: () => {
        refetchAllData();
        // Refetch the discussions query
        queryClient.invalidateQueries({
          queryKey: ['getAdBoardCurrentDiscussions'],
        });
      },
      // Handle errors
      onError: (error: any) => {
        console.error('Error updating progress:', error);
      },
    }
  );

  const isSectionAccessible = isModerator
    ? () => true
    : (sectionDto: SectionDto) => {
        return accessibleSections.some(
          (sectionProgress) => sectionProgress.sectionId === sectionDto.id
        );
      };
  const isSectionFinished = isModerator
    ? () => false
    : (sectionDto: SectionDto) => {
        return finishedSections.some(
          (sectionProgress) => sectionProgress.sectionId === sectionDto.id
        );
      };

  return useMemo<AdBoardContext>(
    () => ({
      isLoading,
      isError,
      adBoardData,
      sectionsData,
      sectionGroupsData,
      allParticipantsData,
      adBoardProgress: participantData?.progress,
      userId: participantData?.userId,
      currentSectionDetails,
      currentSectionProgress,
      updateProgress,
      refetchAllData,
      isSectionAccessible,
      isSectionFinished,
      shouldShowMainHcpNav: () => true,
      shouldCollectResponses: () => (isModerator ? false : true),
    }),
    [
      isLoading,
      isError,
      adBoardData,
      sectionsData,
      sectionGroupsData,
      participantData,
      allParticipantsData,
      currentSectionDetails,
      currentSectionProgress,
      finishedSections,
      updateProgress,
      refetchAllData,
      isSectionAccessible,
      isSectionFinished,
    ]
  );
};
