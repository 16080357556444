import React from 'react';
import { useParams } from 'react-router';
import { Divider } from '@mui/material';

import {
  AdBoardSectionGroupDto,
  DiscussionsAdBoardSectionDto,
} from '@keyops-hcp/dtos';

import { Loader } from '../Loader';
import { DiscussionDrawerHeader } from '../DiscussionDrawerHeader';
import { DiscussionPreview } from '../DiscussionPreview';
import { useDiscussionsByAdBoardId } from '../../hooks';
import i18next from '../../languages/i18n.config';
import { useAdBoardContext } from '../../context';

import { DiscussionDrawerChatContent } from '../DiscussionDrawerChatContent';

interface DiscussionDrawerContentProps {
  displayedDiscussionId: string | undefined;
  toggleDiscussionDrawer: (isDrawerOpen: boolean) => void;
  setDisplayedDiscussionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  optionalAdBoardId?: string; // Can be passed by the admin preview
  optionalCurrentSectionId?: string; // Can be passed by the admin preview
}

const getSectionsGroupInfo = (
  sectionGroupsData: AdBoardSectionGroupDto[],
  sectionId: string
): { sectionGroupTitle: string; sectionGroupIndex: number } => {
  const sectionGroup = sectionGroupsData?.find((sg) =>
    sg.sections.find((s) => s.id === sectionId)
  );
  const sectionGroupTitle = sectionGroup?.title;
  const sectionGroupIndex = sectionGroup?.index;

  return { sectionGroupTitle, sectionGroupIndex };
};

export const DiscussionDrawerContent = ({
  displayedDiscussionId,
  toggleDiscussionDrawer,
  setDisplayedDiscussionId,
  optionalAdBoardId,
  optionalCurrentSectionId,
}: DiscussionDrawerContentProps): JSX.Element => {
  // If admin, passed as props to the component.
  //  If HCP, grab them in the url
  let adBoardId = optionalAdBoardId;
  let currentSectionId = optionalCurrentSectionId;
  if (!adBoardId && !currentSectionId) {
    adBoardId = useParams().adBoardId;
    currentSectionId = useParams().sectionId;
  }

  // Fetch all current discussions (combining the Ad Board general discussion and the current section discussions, if any)
  const {
    data: allDiscussions,
    isLoading,
    isError,
  } = useDiscussionsByAdBoardId(adBoardId);

  // Use sectionGroupsData to grab the title of the current sectionsGroup
  const { sectionGroupsData } = useAdBoardContext();

  if (isLoading) return <Loader />;
  if (isError) return <>Something went wrong</>;
  if (!allDiscussions) return <>No data</>;

  // If a discussion has been selected, display the chat in the Discussion drawer
  if (displayedDiscussionId) {
    return (
      <DiscussionDrawerChatContent
        displayedDiscussionId={displayedDiscussionId}
        toggleDiscussionDrawer={toggleDiscussionDrawer}
        setDisplayedDiscussionId={setDisplayedDiscussionId}
      />
    );
  }

  // If no discussion selected, display a preview of the available discussions on the current section
  return (
    <>
      <DiscussionDrawerHeader
        isDisplayingMessages={false}
        title={i18next.t('ad_board.discussionsDrawer.title')}
        toggleDiscussionDrawer={toggleDiscussionDrawer}
        setDisplayedDiscussionId={setDisplayedDiscussionId}
      />
      <Divider />
      {/* General Discussion */}
      <DiscussionPreview
        discussion={allDiscussions.generalDiscussion}
        setDisplayedDiscussionId={setDisplayedDiscussionId}
      />

      {/* Check if there are discussions */}
      {allDiscussions.discussionsSections.length > 0 &&
        allDiscussions.discussionsSections.map(
          (discussionsSection: DiscussionsAdBoardSectionDto) => {
            const { sectionGroupIndex } = getSectionsGroupInfo(
              sectionGroupsData,
              discussionsSection.id
            );
            return discussionsSection?.content.map((discussionContent) => (
              <DiscussionPreview
                key={discussionContent.discussionId}
                discussion={discussionContent.discussion}
                index={discussionsSection.index}
                topicTitle={discussionContent.discussion.topic} // If many topics are displayed, pass the topic as a title instead of activity's
                activityTitle={discussionsSection.title}
                sectionGroupIndex={sectionGroupIndex}
                setDisplayedDiscussionId={setDisplayedDiscussionId}
              />
            ));
          }
        )}
    </>
  );
};
