import React from 'react';
import { SectionDto } from '@keyops-hcp/dtos';

import { VideoWrapper } from '../VideoWrapper';
import { AttachmentList } from '../AttachmentList';
import { SurveyWrapper } from '../SurveyWrapper';

export const AdBoardSectionDisplay = ({
  section,
  setIsFullScreenData,
}: {
  section: SectionDto;
  setIsFullScreenData: (isFullScreen: boolean) => void;
}) => {
  switch (section.type) {
    case 'video':
      return <VideoWrapper videoId={section?.content.videoId} />;
    case 'attachment':
      return (
        <AttachmentList
          attachmentIds={section?.content.map(
            (attachmentContent) => attachmentContent.attachmentId
          )}
          setIsFullScreenData={setIsFullScreenData}
        />
      );
    case 'survey':
      return <SurveyWrapper />;
  }
  //TODO: may need this later
  // return (
  //   <Container>
  //     <Typography>unrecognized section type ${section.type}</Typography>
  //   </Container>
  // );
};
