import React from 'react';
import { Box, Drawer } from '@mui/material';
import { DiscussionDrawerContent } from '../DiscussionDrawerContent';

interface DiscussionsDrawerProps {
  displayedDiscussionId: string | undefined;
  setDisplayedDiscussionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  discussionDrawerOpen: boolean;
  toggleDiscussionDrawer: (isDrawerOpen: boolean) => void;
  optionalAdBoardId?: string; // Can be passed by the admin preview
  optionalCurrentSectionId?: string; // Can be passed by the admin preview
  modalContainerRef?: React.RefObject<HTMLElement>; // Add this prop for the modal container
}

export const DiscussionsDrawer = ({
  displayedDiscussionId,
  setDisplayedDiscussionId,
  discussionDrawerOpen,
  toggleDiscussionDrawer,
  optionalAdBoardId,
  optionalCurrentSectionId,
  modalContainerRef,
}: DiscussionsDrawerProps): JSX.Element => {
  // TODO: reintroduce mobile handling

  // Set width depending on drawer state
  // When the drawer is opened and display the list of discussions: 33vw
  // When the user selects a discussion to display:70vw
  const drawerWidth = displayedDiscussionId ? '70vw' : '33vw';
  return (
    <Drawer
      open={discussionDrawerOpen}
      onClose={() => {
        toggleDiscussionDrawer(false);
      }}
      anchor={'right'}
      transitionDuration={600}
      container={modalContainerRef?.current || undefined} // Pass the modal container here
      variant='temporary'
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.01)',
          },
        },
      }}
      sx={{
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
        top: '96px',
      }}
      PaperProps={{
        sx: {
          top: '96px',
        },
      }}
      data-testId='discussions-drawer'
    >
      <Box
        height='100%'
        width={drawerWidth}
        bgcolor='background.paper'
        overflow='clip'
        pb={2}
        data-testId='discussions-box'
      >
        <DiscussionDrawerContent
          displayedDiscussionId={displayedDiscussionId}
          setDisplayedDiscussionId={setDisplayedDiscussionId}
          toggleDiscussionDrawer={toggleDiscussionDrawer}
          optionalAdBoardId={optionalAdBoardId}
          optionalCurrentSectionId={optionalCurrentSectionId}
        />
      </Box>
    </Drawer>
  );
};
