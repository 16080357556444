import { useMutation } from '@tanstack/react-query';
import {
  AdBoardDiscussionSectionProgressCombined,
  DiscussionMessageDto,
} from '@keyops-hcp/dtos';

import { createOneDiscussionMessage } from '../api/discussionMessages';
import { queryClient } from '../wrappers';
import { useAdBoardContext } from '../context';
import { analytics } from '../utils/analytics';

/**
 *
 * @param {string} adBoardId - The id of the current Ad Board
 * @param {string} currentSectionId - The id of the current section
 * @returns
 */
export const usePostDiscussionMessage = () => {
  const { currentSectionProgress, updateProgress } = useAdBoardContext();

  return useMutation<
    DiscussionMessageDto,
    unknown,
    { message: string; discussionId: string }
  >(
    async ({ message, discussionId }): Promise<DiscussionMessageDto> => {
      const createdMessage = await createOneDiscussionMessage(
        message,
        discussionId
      );

      return createdMessage;
    },
    {
      onSuccess: (createdMessage) => {
        // Update the progress
        const existingFinishedTopics =
          (currentSectionProgress as AdBoardDiscussionSectionProgressCombined)
            ?.finishedTopics || [];
        if (
          !existingFinishedTopics.includes(createdMessage.discussionId) &&
          updateProgress
        ) {
          const finishedTopics = [
            ...existingFinishedTopics,
            createdMessage.discussionId,
          ];
          analytics.track(`AdBoard Progress Discussion`, { finishedTopics });
          updateProgress({
            finishedTopics,
          });
        }
        // Refetch the discussions query
        queryClient.invalidateQueries({
          queryKey: ['getAdBoardCurrentDiscussions'],
        });
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
};
