import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQueries, useQueryClient } from '@tanstack/react-query';

import { getOneAdBoard } from '../api/adBoards';
import { getAllAdBoardParticipants } from '../api/participants';
import { AdBoardContext } from '../context';

// Used for the admin adboard context
export const useAdminAdBoard = () => {
  const queryClient = useQueryClient();

  const { id: adBoardId } = useParams();

  // Use useQueries to fetch adBoard, sections, and participant data simultaneously
  const [adBoardQuery, allParticipantsQuery] = useQueries({
    queries: [
      {
        queryKey: ['adBoard', adBoardId],
        queryFn: () => getOneAdBoard(adBoardId),
      },
      {
        queryKey: ['all participants', adBoardId],
        queryFn: () => getAllAdBoardParticipants(adBoardId),
      },
    ],
  });

  const isLoading = adBoardQuery.isLoading;
  const isError = adBoardQuery.isError;

  const adBoardData = adBoardQuery?.data;
  const sectionsData = adBoardData?.sections;
  const sectionGroupsData = adBoardData?.sectionGroups;
  const allParticipantsData = allParticipantsQuery?.data;

  // TODO: find a way to have it again?
  // const currentSectionDetails = useMemo(
  //   () => sectionsData?.find((section: SectionDto) => section.id === sectionId),
  //   [sectionsData, sectionId]
  // );

  // Memoize the refetchAllData function
  const refetchAllData = useCallback(() => {
    queryClient.invalidateQueries(['adBoard', adBoardId]);
  }, [queryClient, adBoardId]);

  return useMemo<AdBoardContext>(
    () => ({
      isLoading,
      isError,
      adBoardData,
      sectionsData,
      sectionGroupsData,
      allParticipantsData,
      refetchAllData,
      isSectionAccessible: () => true,
      isSectionFinished: () => false,
      shouldShowMainHcpNav: () => false,
      shouldCollectResponses: () => false,
      updateProgress: () => {},
    }),
    [
      isLoading,
      isError,
      adBoardData,
      sectionsData,
      sectionGroupsData,
      allParticipantsData,
      refetchAllData,
    ]
  );
};
