import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { createOneAdBoardInvite } from '../../../../api/adboard-invites-api';
import { useEditContext } from 'react-admin';

export const CreateAdBoardInviteDialog = ({
  onSave = () => {},
}: {
  onSave?: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const { record: adBoard } = useEditContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button variant='outlined' onClick={handleClickOpen}>
        Add participant
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            formJson.adBoardId = adBoard.id;
            //set a participant role.
            formJson.role = 'participant';
            await createOneAdBoardInvite(formJson);
            onSave();
            handleClose();
          },
        }}
      >
        <DialogTitle>Add participant</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <TextField
            required
            id='email'
            name='email'
            label='Email Address'
            type='email'
            fullWidth
            variant='outlined'
          />
          <TextField
            required
            id='firstName'
            name='firstName'
            label='First Name'
            type='text'
            fullWidth
            variant='outlined'
          />
          <TextField
            required
            id='lastName'
            name='lastName'
            label='Last Name'
            type='text'
            fullWidth
            variant='outlined'
          />
          <TextField
            required
            id='honorarium'
            name='honorarium'
            label='Honorarium'
            type='number'
            fullWidth
            variant='outlined'
            InputProps={{ inputProps: { min: 0, max: 10000 } }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' type='submit'>
            Add Participant
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
