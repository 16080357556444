import React from 'react';
import { Box, IconButton, Link, Skeleton, useTheme } from '@mui/material';
import { FiMessageCircle, FiRadio } from 'react-icons/fi';

import { useAdBoardContext } from '../../context';
import i18next from '../../languages/i18n.config';
import { KeyopsHeader3, KeyopsSubTitle2 } from '../Headers';
import { formatDateWithSuffix } from '../../utils/datetime';
import { ADBOARD_LANDING_ROOT } from '../../utils/physician-ui-routes';
import { COMPONENT_FEATURE_FLAGS } from '../../utils/component-feature-flags';
import { Attendees } from '../Attendees';
import { Notifications } from '../Notifications';

interface DesktopAdBoardTopBarProps {
  toggleDiscussionDrawer: (isDrawerOpen: boolean) => void;
}

export const DesktopAdBoardTopBar = ({
  toggleDiscussionDrawer,
}: DesktopAdBoardTopBarProps): JSX.Element => {
  const { palette } = useTheme();

  const { isLoading, isError, adBoardData, shouldShowMainHcpNav } =
    useAdBoardContext();
  if (isError) return <>Something went wrong</>;

  return (
    <>
      <Box flexGrow={1}>
        <KeyopsHeader3 fontWeight={400} mb={'8px'}>
          {isLoading || !adBoardData ? (
            <Skeleton data-testid='ad-board-title-skeleton' />
          ) : (
            adBoardData.title
          )}
        </KeyopsHeader3>
        <Box display={'flex'} gap={4}>
          <KeyopsSubTitle2 fontWeight={400}>
            {isLoading || !adBoardData ? (
              <Skeleton
                data-testid='ad-board-company-name-skeleton'
                sx={{ minWidth: '50px' }}
              />
            ) : (
              adBoardData.company.name
            )}
          </KeyopsSubTitle2>
          {adBoardData?.endDate && (
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <FiRadio color={palette.keyops.teal} fontSize={20} />
              <KeyopsSubTitle2>
                {i18next.t('ad_board.adBoardTopBar.openUntil', {
                  endDate: formatDateWithSuffix(adBoardData?.endDate),
                })}
              </KeyopsSubTitle2>
            </Box>
          )}
          <Link
            data-testid='adboard-landing-link'
            href={`${ADBOARD_LANDING_ROOT}/${adBoardData?.id}`}
            underline='none'
          >
            <KeyopsSubTitle2 color={'primary'}>
              {i18next.t('ad_board.adBoardTopBar.viewDetails')}
            </KeyopsSubTitle2>
          </Link>
        </Box>
      </Box>
      <Attendees sx={{ mr: 2 }} />
      {COMPONENT_FEATURE_FLAGS.ADBOARD_DISCUSSION && (
        <IconButton
          data-testid='discussion-board-button'
          aria-label='discussion-board-button'
          sx={{ color: palette.keyops.blue.dark, mt: -0.2 }}
          onClick={() => toggleDiscussionDrawer(true)}
        >
          <FiMessageCircle fontSize={25} />
        </IconButton>
      )}
      {shouldShowMainHcpNav() && <Notifications />}
    </>
  );
};
