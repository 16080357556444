import {
  AdBoardDiscussionSectionProgressCombined,
  AdBoardSectionProgressDto,
  DiscussionAdBoardSectionContentDto,
  DiscussionsAdBoardSectionDto,
} from '@keyops-hcp/dtos';

/**
 * Check if the given discussion is finished
 * @param currentDiscussionId
 * @param currentSectionProgress
 * @returns {boolean}
 */
export const isCurrentDiscussionFinished = (
  currentDiscussionId: string,
  currentSectionProgress: AdBoardSectionProgressDto
): boolean => {
  return (
    currentSectionProgress as AdBoardDiscussionSectionProgressCombined
  )?.finishedTopics?.includes(currentDiscussionId);
};

/**
 * Check if the given section discussion is finished by ensuring finishedTopics includes all discussions
 * @param totalDiscussionsCount
 * @param currentSectionProgress
 * @returns {boolean}
 */
export const isCurrentDiscussionSectionFinished = (
  currentSectionProgress: AdBoardSectionProgressDto
): boolean => {
  return currentSectionProgress.finished;
};

/**
 * Returns the enxt discussion in a fiven section or undefined
 * @param totalDiscussionsCount
 * @param currentSectionProgress
 * @returns {DiscussionAdBoardSectionContentDto | undefined}
 */
export const nextDiscussionInSection = (
  currentDiscussionSection: DiscussionsAdBoardSectionDto,
  currentTopicIndex: number
): DiscussionAdBoardSectionContentDto | undefined => {
  if (currentTopicIndex + 1 === currentDiscussionSection?.content.length)
    return undefined;

  return currentDiscussionSection?.content[currentTopicIndex + 1];
};
