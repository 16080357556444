import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AdBoardSectionGroupDto, SectionDto } from '@keyops-hcp/dtos';
import { ActivityListItem } from '../ActivityListItem';
import { KeyopsHeader3 } from '../Headers';
import { useAdBoardContext } from '../../context';

interface SectionActivitiesViewProps {
  sectionGroup: AdBoardSectionGroupDto;
  setCurrentSectionId: (id: string) => void;
  setDisplayedDiscussionId: React.Dispatch<React.SetStateAction<string>>;
  setDiscussionDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SectionActivitiesView = ({
  sectionGroup,
  setCurrentSectionId,
  setDisplayedDiscussionId,
  setDiscussionDrawerOpen,
}: SectionActivitiesViewProps): JSX.Element => {
  const { isSectionAccessible, isSectionFinished, adBoardProgress } =
    useAdBoardContext();
  // Add the expanded state here
  const [expanded, setExpanded] = useState<boolean>(true);
  // Toggle function to expand/collapse the accordion
  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };

  const ContentItem = ({ section }: { section: SectionDto }) => {
    const discussionId =
      section.type === 'discussion'
        ? section?.content[0]?.discussionId
        : undefined; // use first topic

    return (
      <ActivityListItem
        key={section.id}
        text={section.title}
        sectionId={section.id}
        sectionType={section.type}
        finished={isSectionFinished(section)}
        accessible={isSectionAccessible(section)}
        discussionId={discussionId}
        setCurrentSectionId={setCurrentSectionId}
        setDisplayedDiscussionId={setDisplayedDiscussionId}
        setDiscussionDrawerOpen={setDiscussionDrawerOpen}
      />
    );
  };

  return (
    <Accordion
      key={sectionGroup.id}
      elevation={0}
      disableGutters
      sx={{
        '&::before': {
          display: 'none',
        },
      }}
      expanded={expanded}
      onChange={handleToggle} // Set the toggle function here
    >
      <AccordionSummary
        id={`${sectionGroup.id}-header`}
        aria-controls={`${sectionGroup.id}-content`}
        expandIcon={<ExpandMoreIcon />}
        sx={{
          flexDirection: 'row-reverse',
          gap: 1,
          px: 1,
          // TODO: un-comment when we have the calculation of current sectionGroup estimated time here (for now, only AttachmentSection has it) */}
          // alignItems: 'start',
          // mt: 1.5,
          '.MuiAccordionSummary-content': {
            // my: 0, // TODO: replace my by this value when we have the calculation of current sectionGroup estimated time
            my: 1.5,
          },
        }}
      >
        {/* <Box> */}
        <KeyopsHeader3
          fontSize={16}
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          {sectionGroup.title || 'Untitled section'}
        </KeyopsHeader3>
        {/* TODO: un-comment when we have the calculation of current sectionGroup estimated time here (for now, only AttachmentSection has it) */}
        {/* <KeyopsSubTitle2>
            {countFinishedSectionsInSectionGroup(sectionGroup, adBoardProgress)}
            /{sectionGroup.sections.length}
          </KeyopsSubTitle2> */}
        {/* </Box> */}
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        {sectionGroup?.sections.map((section) => (
          <ContentItem key={section.id} section={section} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
