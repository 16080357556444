import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Box, Typography } from '@mui/material';
import { FaArrowRight } from 'react-icons/fa6';

import { DiscussionDto, DiscussionsAdBoardSectionDto } from '@keyops-hcp/dtos';

import { DiscussionMessageForm } from '../DiscussionMessageForm';
import { DiscussionChatMessages } from '../DiscussionChatMessages';
import { DiscussionChatHeader } from '../DiscussionChatHeader';
import i18next from '../../languages/i18n.config';
import { useAdBoardContext } from '../../context';
import { useDiscussionsByAdBoardId } from '../../hooks';
import { AdBoardCompletedTaskButton } from '../AdBoardCompletedTaskButton';
import { getUrl } from '../../utils/common-utils';
import { ADBOARD_SECTIONS } from '../../utils/physician-ui-routes';
import {
  isCurrentDiscussionFinished,
  nextDiscussionInSection,
} from '../../utils/adboard-discussions.utils';
import { AdBoardButton } from '../AdBoardButton';
import { getNextSectionType } from '../../utils/adboard-sections.utils';
import { AdBoardFinishedCard } from '../AdBoardFinishedCard';

interface DiscussionChatProps {
  discussion: DiscussionDto;
  currentTopicIndex?: number;
  topicsCount?: number;
  setDisplayedDiscussionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  toggleDiscussionDrawer: (isDrawerOpen: boolean) => void;
}

export const DiscussionChat = ({
  discussion,
  currentTopicIndex,
  topicsCount,
  setDisplayedDiscussionId,
  toggleDiscussionDrawer,
}: DiscussionChatProps): JSX.Element => {
  // Get the progress for this discussion to handle topics navigation
  const {
    adBoardData,
    sectionsData: sections,
    currentSectionProgress,
    sectionGroupsData,
    currentSectionDetails,
  } = useAdBoardContext();
  const { adBoardId, sectionId } = useParams();
  const navigate = useNavigate();

  // Fetch all discussions
  const { isSuccess } = useDiscussionsByAdBoardId(adBoardId);

  // Find the index of the current section
  const [currentSectionIndex, setCurrentSectionIndex] = useState(undefined);
  useEffect(() => {
    if (sections && sections.length > 0 && sectionId) {
      setCurrentSectionIndex(
        sections.findIndex((section) => section.id === sectionId)
      );
    }
  }, [sections, sectionId]);

  const nextDiscussion = nextDiscussionInSection(
    currentSectionDetails as DiscussionsAdBoardSectionDto,
    currentTopicIndex
  );
  const nextSectionType = getNextSectionType(sectionGroupsData, sectionId);

  const handleNavigateToNextTopic = () => {
    // Display the next discussion in the same activity
    if (nextDiscussion) {
      setDisplayedDiscussionId(nextDiscussion.discussionId);
      // No more discussions in the same activity, check the next section
    } else {
      const nextSection = sections[currentSectionIndex + 1];

      if (nextSectionType === 'finished') {
        return;
      } else if (nextSectionType === 'discussion') {
        setDisplayedDiscussionId(
          (nextSection as DiscussionsAdBoardSectionDto)?.content[0].discussionId
        );
      } else {
        toggleDiscussionDrawer(false);
        setDisplayedDiscussionId(undefined);
        navigate(
          getUrl(ADBOARD_SECTIONS, {
            adBoardId,
            sectionId: nextSection.id,
          })
        );
      }
    }
  };

  return (
    <Box display='flex' flexDirection='column' height='80%' p={`0px 58px`}>
      {/* Only display the header for section's discussions */}
      {discussion?.topic !==
        i18next.t('ad_board.discussionsDrawer.generalDiscussion.title') && (
        <DiscussionChatHeader
          discussion={discussion}
          currentTopicIndex={currentTopicIndex}
          topicsCount={topicsCount}
        />
      )}

      <DiscussionChatMessages discussion={discussion} />

      {/* Display a helper message for general discussion without any messages*/}
      {discussion?.topic ===
        i18next.t('ad_board.discussionsDrawer.generalDiscussion.title') &&
        discussion?.messages.length === 0 && (
          <Typography
            textAlign='center'
            fontSize={`16px`}
            color={'keyops.gray.600'}
            pb='8px'
          >
            {i18next.t(
              'ad_board.discussionsDrawer.generalDiscussion.helperMessage'
            )}
          </Typography>
        )}
      <DiscussionMessageForm
        discussionId={discussion?.id}
        adBoardId={adBoardId}
      />

      {/* Navigation button */}
      {isSuccess &&
      isCurrentDiscussionFinished(discussion?.id, currentSectionProgress) ? (
        nextSectionType === 'finished' ? (
          <AdBoardFinishedCard endDate={adBoardData.endDate} />
        ) : nextDiscussion ? (
          <AdBoardButton
            onClick={handleNavigateToNextTopic}
            endIcon={<FaArrowRight />}
          >
            {i18next.t('ad_board.completed_task_button.next_topic')}
          </AdBoardButton>
        ) : (
          <AdBoardCompletedTaskButton
            buttonContent={
              nextSectionType === 'section'
                ? i18next.t('ad_board.completed_task_button.next_section')
                : i18next.t('ad_board.completed_task_button.next_activity')
            }
            onClick={handleNavigateToNextTopic}
            endIcon={<FaArrowRight />}
          />
        )
      ) : (
        <></>
      )}
    </Box>
  );
};
