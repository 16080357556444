import React from 'react';
import { useParams } from 'react-router';
import { Divider } from '@mui/material';

import {
  AllAdBoardDiscussionsDto,
  DiscussionsAdBoardSectionDto,
} from '@keyops-hcp/dtos';

import { DiscussionDrawerHeader } from '../DiscussionDrawerHeader';
import { DiscussionChat } from '../DiscussionChat';
import { useDiscussionsByAdBoardId } from '../../hooks';
import i18next from '../../languages/i18n.config';
import { Loader } from '../Loader';

interface DiscussionDrawerChatContentProps {
  displayedDiscussionId: string | undefined;
  toggleDiscussionDrawer: (isDrawerOpen: boolean) => void;
  setDisplayedDiscussionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

/**
 * Used to determine the conversation to display in allCurrentDiscussions, given a selected id
 * @param displayedDiscussionId
 * @param allCurrentDiscussions
 * @returns the discussion to display in the Discussion Drawer
 */
const findDiscussionSectionToDisplay = (
  displayedDiscussionId: string,
  allCurrentDiscussions: AllAdBoardDiscussionsDto
): DiscussionsAdBoardSectionDto => {
  let discussionSectionToDisplay;
  for (const discussionsSection of allCurrentDiscussions.discussionsSections) {
    if (
      discussionsSection?.content?.find(
        (discu) => discu.discussionId === displayedDiscussionId
      )
    ) {
      discussionSectionToDisplay = discussionsSection;
    }
  }
  if (discussionSectionToDisplay) {
    return discussionSectionToDisplay;
  }
  console.error(
    `Discussion to display not found - passed id:`,
    displayedDiscussionId
  );
};

const countCurrentSectionDiscussions = (
  currentSectionDetails: DiscussionsAdBoardSectionDto,
  displayedDiscussionId: string
): number => {
  // Otherwise, check in all AB discussions
  if (!currentSectionDetails?.content?.length)
    console.error(
      `Impossible to count the discussions for the current section`,
      displayedDiscussionId
    );
  return currentSectionDetails?.content?.length;
};

export const DiscussionDrawerChatContent = ({
  displayedDiscussionId,
  toggleDiscussionDrawer,
  setDisplayedDiscussionId,
}: DiscussionDrawerChatContentProps): JSX.Element => {
  const adBoardId = useParams().adBoardId;

  // Fetch all current discussions (combining the Ad Board general discussion and the accessebile discussions, if any)
  const { data: allDiscussions, isLoading } =
    useDiscussionsByAdBoardId(adBoardId);

  // Helpers for general discussion
  const generalDiscussionTitle = i18next.t(
    'ad_board.discussionsDrawer.generalDiscussion.title'
  );
  const isGeneralDiscussion =
    allDiscussions?.generalDiscussion?.id === displayedDiscussionId;

  if (isGeneralDiscussion) {
    const displayedDiscussion = allDiscussions.generalDiscussion;
    return (
      <>
        <DiscussionDrawerHeader
          isDisplayingMessages={true}
          title={generalDiscussionTitle}
          description={undefined}
          toggleDiscussionDrawer={toggleDiscussionDrawer}
          setDisplayedDiscussionId={setDisplayedDiscussionId}
        />
        <Divider />
        <DiscussionChat
          discussion={displayedDiscussion}
          setDisplayedDiscussionId={setDisplayedDiscussionId}
          toggleDiscussionDrawer={toggleDiscussionDrawer}
        />
      </>
    );
  } else {
    if (isLoading) return <Loader />;
    const discussionSectionToDisplay = findDiscussionSectionToDisplay(
      displayedDiscussionId,
      allDiscussions
    );
    const discussionToDisplay = discussionSectionToDisplay?.content?.find(
      (discuContent) => discuContent.discussionId === displayedDiscussionId
    ).discussion;
    if (discussionToDisplay) {
      return (
        <>
          <DiscussionDrawerHeader
            isDisplayingMessages={true}
            title={discussionSectionToDisplay.title}
            description={discussionSectionToDisplay.description}
            toggleDiscussionDrawer={toggleDiscussionDrawer}
            setDisplayedDiscussionId={setDisplayedDiscussionId}
          />
          <Divider />
          <DiscussionChat
            discussion={discussionToDisplay}
            topicsCount={countCurrentSectionDiscussions(
              discussionSectionToDisplay,
              displayedDiscussionId
            )}
            currentTopicIndex={
              discussionSectionToDisplay?.content?.find(
                (discuContent) =>
                  discuContent.discussionId === displayedDiscussionId
              )?.index
            }
            setDisplayedDiscussionId={setDisplayedDiscussionId}
            toggleDiscussionDrawer={toggleDiscussionDrawer}
          />
        </>
      );
    }
  }
};
